<template>
  <div class="h-full flex flex-col pb-0">
    <loader :loading="this.loading" :backdrop="true" />
    <div class="form-row">
      <div class="form-col">
        <label for="categories">main topic</label>
        <Dropdown
          trackBy="intent_category_name"
          label="intent_category_name"
          :options="categories"
          :value="category"
          :disabled="!this.community || (toDelete && toDelete.type !== '')"
          @input="setCategoryValue"
          objectMode
        />
      </div>
      <div class="form-col">
        <label for="intents" :class="{ isDisabled: !this.category }"
          >conversation detail</label
        >
        <Dropdown
          trackBy="intent_name"
          label="suggestion_text"
          :options="intents"
          :value="intent"
          @input="setIntentValue"
          :disabled="!this.category || (toDelete && toDelete.type !== '')"
          objectMode
        />
      </div>
      <div class="form-col">
        <label for="sub_intent" :class="{ isDisabled: !this.intent }"
          >next level detail</label
        >
        <Dropdown
          trackBy="intent_name"
          label="suggestion_text"
          :options="subIntents"
          :value="subIntent"
          @input="setSubIntentValue"
          :disabled="!this.intent || (toDelete && toDelete.type !== '')"
          objectMode
        />
      </div>
    </div>
    <div
      class="flex flex-1 overflow-auto justify-between mt-3"
      v-if="category && intent && subIntent"
    >
      <div class="section bg-gray-100 border border-gray-400 p-4">
        <p class="font-bold text-lg mb-4">
          standard + custom responses
        </p>
        <global-response
          v-for="response in responses.communityIntentUsesGlobalIntentResponse"
          :key="response.id_intent_response"
          :response="response"
          :isDisable="toDelete && toDelete.type !== ''"
        />
        <custom-response
          v-for="response in responses.communityIntentUsesCommunityIntentResponse"
          :key="response.id_intent_response"
          :response="response"
          :disableResponses="toDelete && toDelete.type !== ''"
          :isToDelete="response.intent_response_id === toDelete.id"
          :onSelectToDelete="
            () => handleDelete({ id: response.intent_response_id, type: 'res' })
          "
        />
      </div>
      <div class="section pl-4 pr-4 p-b4">
        <p class="font-bold text-lg mb-4">photos</p>
        <div
          v-if="photos.length"
          class="flex flex-1 flex-wrap justify-between items-center focus:outline-none"
        >
          <card-photo
            v-for="photo in photos"
            :key="photo.card_id"
            :card="photo.card"
            :isToDelete="photo.card_id === toDelete.id"
            :imgSelected="toDelete.id"
            :onSelectToDelete="
              () => handleDelete({ id: photo.card_id, type: 'photo' })
            "
          />
        </div>
        <div v-else class="text-center flex-1">
          <div v-if="(toDelete && toDelete.type !== '')">
            <button :disabled="(toDelete && toDelete.type !== '')" :class="{isDisabled: toDelete && toDelete.type !== ''}" class="underline mt-32 focus:outline-none"
                    style=" color: var(--highlightColor500);">
              add photo
            </button>
          </div>
          <modal-link v-else route="conversations_responses.create-card">
            <button class="underline mt-32 focus:outline-none"
                    style=" color: var(--highlightColor500);">
              add photo
            </button>
          </modal-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import AuthMixin from '@/components/auth/AuthMixin'
import NotifyMixin from '@/mixins/NotifyMixin'
import ModalNavigation from '@/mixins/ModalNavigation'
import ModalLink from '@/components/ui/modals/ModalLink'
import Loader from '@/components/ui/Loader'
import Dropdown from '@/components/ui/Dropdown'
import ConversationMixin from '@/components/digital_human/ConversationMixin'
import GlobalResponse from '@/components/digital_human/GlobalResponse'
import CardPhoto from '@/components/digital_human/CardPhoto'
import CustomResponse from '@/components/digital_human/CustomResponse'
import EventBus from '@/utils/EventBus'

export default {
  mixins: [AuthMixin, NotifyMixin, ModalNavigation, ConversationMixin],
  components: {
    Dropdown,
    GlobalResponse,
    CustomResponse,
    Loader,
    CardPhoto,
    ModalLink,
  },
  data() {
    return {
      toDelete: {
        type: '',
        id: '',
      },
    }
  },
  computed: {
    ...mapGetters({
      backModal: 'modals/getLastModal',
    }),
  },
  methods: {
    handleSetupRedirect() {
      this.$router.push( {name: 'setup-wizard.index'}).then( () => {});
    },
    handleDelete(toDelete) {
      this.toDelete = toDelete;
      const type = toDelete.type === 'res' ? 'response' : toDelete.type;
      EventBus.emit('show-modal-confirmation', {
        id: 'dh-manage-conversation-responses',
        title: 'manage conversation responses',
        showConfirmation: true,
        confirmationMessage: `Are you sure you want to delete this ${type}?`,
        confirmationType: 'warning',
      })
    },
    fetchCategories() {
      this.setLoading(true)

      this.$dhDataProvider
        .getList('categoriesCustom')
        .then((res) => this.loadCategories(res))
        .catch((error) => this.notifyError(error.message))
        .finally(() => this.setLoading(false))
    },
    fetchIntents(categoryName) {
      this.setLoading(true)

      if (this.community && this.community?.id.trim() !== '')
        this.$dhDataProvider
          .getList('customIntents', {
            communityId: this.community?.id,
            category: categoryName,
          })
          .then((res) => this.loadIntents(res))
          .catch((error) => this.notifyError(error.message))
          .finally(() => this.setLoading(false))
    },
    fetchSubIntents(intentName) {
      this.setLoading(true)
      this.$dhDataProvider
        .getList('subIntentsLevel', {
          communityId: this.community?.id,
          intent: intentName,
        })
        .then((res) => this.loadSubIntents(res))
        .catch((error) => this.notifyError(error.message))
        .finally(() => this.setLoading(false))
    },
    fetchResponses(intentName) {
      this.setLoading(true)
      this.$dhDataProvider
        .getList('allCustomResponsesByCommunity', {
          communityId: this.community?.id,
          intent: encodeURIComponent(intentName),
        })
        .then((res) => this.loadResponses(res))
        .catch((error) => this.notifyError(error.message))
        .finally(() => this.setLoading(false))
    },
    fetchPhotos(intentName) {
      this.setLoading(true)
      this.$dhDataProvider
        .getList('cards', {
          community_id: this.community.id,
          intent: intentName,
        })
        .then((res) => this.loadPhotos(res))
        .catch((error) => this.notifyError(error.message))
        .finally(() => this.setLoading(false))
    },
    setCategoryValue(value) {
      this.cleanState('category')
      if (!value) return
      this.setCategory(value)
      this.fetchIntents(value?.description)
    },
    setIntentValue(value) {
      this.cleanState('intent')
      this.cleanState('subIntent')
      if (!value) return
      this.setIntent(value)
      this.fetchSubIntents(value?.intent_name)
    },
    setSubIntentValue(value) {
      this.cleanState('subIntent')
      if (!value) return
      const subIntentValue =
        value?.intent_name === 'general' ? this.intent : value
      this.setSubIntent(value)
      this.fetchResponses(subIntentValue.intent_name)
      this.fetchPhotos(subIntentValue.intent_name)
    },
    deletePhoto() {
      this.setLoading(true)
      this.$dhDataProvider
        .delete('card', { id: this.toDelete.id })
        .then(() => {
          const value =
            this.subIntent?.intent_name === 'general'
              ? this.intent
              : this.subIntent
          this.fetchPhotos(value.intent_name)
          this.toDelete = { id: '', type: '' }
          this.notifySuccess('The photo was deleted successfully')
        })
        .catch((error) => this.notifyError(error))
        .finally(() => this.setLoading(false))
    },
    deleteResponse() {
      this.$dhDataProvider
        .delete('customResponse', { id: this.toDelete.id })
        .then(() => {
          const value =
            this.subIntent?.intent_name === 'general'
              ? this.intent
              : this.subIntent
          this.fetchResponses(value.intent_name)
          this.toDelete = { id: '', type: '' }
          this.notifySuccess('The response was deleted successfully')
        })
        .catch((error) => this.notifyError(error))
        .finally(() => this.setLoading(false))
    },
    onDelete() {
      if (this.toDelete.type === 'res') {
        this.deleteResponse()
      } else if (this.toDelete.type === 'photo') {
        this.deletePhoto()
      }
    },
    cancelDelete() {
      this.toDelete = { id: '', type: '' }
    },
    checkIfCommunityHaveAConversation(communityId) {
      this.setLoading(true)
      this.$dhDataProvider
        .getOne('communityHasConversation', { id: communityId })
        .then((res) => {
          this.setLoading(false)
          if (!res.community_has_conversation) this.handleSetupRedirect();
        })
        .catch((error) => this.notifyError(error.message))
    },
  },
  mounted() {
    if (this.community?.id) this.checkIfCommunityHaveAConversation(this.community.id)
  },
  created() {
    this.fetchCategories()
    if (this.community) {
      if (this.category && this.intent && this.subIntent) {
        const value =
          this.subIntent?.intent_name === 'general'
            ? this.intent
            : this.subIntent
        this.fetchResponses(value.intent_name)
        this.fetchPhotos(value.intent_name)
      }
    } else {
      this.notifyError(
        'please select a community to continue, then refresh the browser'
      )
    }

    EventBus.on('confirm-dh-manage-conversation-responses', () => {
      this.onDelete()
    })
    EventBus.on('cancel-confirmation-dh-manage-conversation-responses', () => {
      this.cancelDelete()
    })
  },
  unmounted() {
    if (!this.backModal) {
      this.cleanState('category')
    }
  },
}
</script>

<style scoped>
.section {
  width: 48%;
  @apply flex-1 overflow-auto;
}
.isDisabled {
  @apply text-gray-400 !important;
}
</style>
