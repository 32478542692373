<template>
  <div class="flex items-start mb-4">
    <label
      class="flex mb-4 justify-between w-full"
      :class="{ delete: isToDelete }"
    >
      <input
        type="checkbox"
        class="w-4 h-4 mt-1"
        :checked="!response.disabled"
        :disabled="(response.blocked === 1 || disableResponses)"
        @change="onChange"
      />
    <div class="flex-1 mx-2">
      <p class="mb-2">  
        {{ response.response_text }}
      </p>
      <p v-if="response.spanish_response_text" class="text-gray-600 text-sm mb-2 ">  
        {{ response.spanish_response_text }}
      </p>
      <p v-if="response.status === 'outDatedTranslated'" class="text-orange-700 text-sm mb-2">  
        outdated
      </p>
      <p v-if="response.status === 'notTranslated'" class="text-red-600 text-sm mb-2">  
        no spanish translation
      </p>
    </div>
    </label>
    <div v-if="disableResponses" class="flex items-center">
      <icon :class="{isDisabled: disableResponses}" name="pencil" class="h-5 w-5 mr-1 text-blue-500" />
      <button :disabled="disableResponses" class="focus:outline-none" @click="this.onSelectToDelete">
        <icon name="closeCircle" :class="{isDisabled: disableResponses}" class="h-5 w-5 text-red-600 cursor-pointer" />
      </button>
    </div>
    <div v-else class="flex items-center">
      <modal-link
        route="conversations_responses.edit-response"
        :params="{ id: response.intent_response_id }"
      >
        <icon name="pencil" class="h-5 w-5 mr-1 text-blue-500" />
      </modal-link>
      <button class="focus:outline-none" @click="this.onSelectToDelete">
        <icon name="closeCircle" class="h-5 w-5 text-red-600 cursor-pointer" />
      </button>
    </div>
  </div>
</template>

<script>
import AuthMixin from '@/components/auth/AuthMixin';
import ModalLink from '@/components/ui/modals/ModalLink'
import Icon from '@/components/ui/Icon'
import NotifyMixin from '@/mixins/NotifyMixin'
import ConversationMixin from '@/components/digital_human/ConversationMixin'

export default {
  mixins: [AuthMixin, NotifyMixin, ConversationMixin],
  components: {
    Icon,
    ModalLink,
  },
  props: {
    isToDelete: {
      type: Boolean,
      default: false,
    },
    disableResponses: {
      type: Boolean,
      default: false,
    },
    onSelectToDelete: {
      type: Function,
      required: true,
    },
    response: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  methods: {
    onChange() {
      this.setLoading(true)
      const intentValue =
        this.subIntent?.intent_name === 'general' ? this.intent : this.subIntent
      this.$dhDataProvider.create('toggleCustomResponse', {data: {
          intent_response_id: this.$props.response.intent_response_id,
          intent_name: intentValue.intent_name,
          community_id: this.community?.id,
        }})
        .then(() => {
          if (this.$props.response.blocked !== 1) this.notifySuccess('The response was updated successfully')
        })
        .catch((error) => this.notifyError(error.message))
        .finally(() => this.setLoading(false))
    },
  },
}
</script>

<style scoped>
.custom {
  @apply text-blue-500;
}
.delete {
  @apply text-red-600;
}
strong {
  font-size: 1.01rem;
}
.isDisabled {
  @apply cursor-not-allowed;
  color:  grey;
}
</style>
