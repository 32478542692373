<template>
  <label class="flex justify-between w-full mb-8">
    <input
      type="checkbox"
      class="w-4 h-4 mt-1"
      :checked="response.is_active"
      @change="onChange"
      :disabled="isDisable"
    />
    <div class="flex-1 mx-2">
      <p class="mb-2">  
        {{ response.response_text }}
      </p>
      <p v-if="response.spanish_response_text" class="text-gray-600 text-sm mb-2 ">  
        {{ response.spanish_response_text }}
      </p>
      <p v-if="response.status === 'outDatedTranslated'" class="text-orange-700 text-sm mb-2">  
        outdated
      </p>
      <p v-if="response.status === 'notTranslated'" class="text-red-600 text-sm mb-2">  
        no spanish translation
      </p>
    </div>
  </label>
</template>

<script>
import AuthMixin from '@/components/auth/AuthMixin'
import NotifyMixin from '@/mixins/NotifyMixin'
import ConversationMixin from '@/components/digital_human/ConversationMixin'

export default {
  mixins: [AuthMixin, NotifyMixin, ConversationMixin],
  props: {
    response: {
      type: Object,
      default() {
        return {}
      },
    },
    isDisable: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    onChange() {
      this.setLoading(true)
      const intentValue =
        this.subIntent?.intent_name === 'general' ? this.intent : this.subIntent
      this.$dhDataProvider.create('toggleGlobalResponse', {data: {
          intent_response_id: this.$props.response.intent_response_id,
          intent_name: intentValue.intent_name,
          community_id: this.community?.id,
        }})
        .then(() => this.notifySuccess('The response was updated successfully'))
        .catch((error) => this.notifyError(error.message))
        .finally(() => this.setLoading(false))
    },
  },
}
</script>

<style scoped>
strong {
  font-size: 1.01rem;
}
</style>
