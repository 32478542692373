<template>
  <div class="card mb-4 p-2" :class="{ delete: isToDelete, disabled: !isToDelete && imgSelected !== '' }">
    <div v-if="!isToDelete && imgSelected !== ''" class="relative container-image w-3/4">
      <img
        :src="card.image_url"
        :alt="card.title"
        @error="imageLoadOnError"
        class="w-full h-64 bg-contain"
      />
    </div>
    <div v-else-if="isToDelete && imgSelected === card.id_card" class="relative container-image">
      <img
        :src="card.image_url"
        :alt="card.title"
        @error="imageLoadOnError"
        class="w-full h-64 bg-contain"
      />
    </div>
    <div v-else class="relative container-image">
      <button
        class="hide absolute right-1 top-1 focus:outline-none"
        @click="this.onSelectToDelete"
      >
        <icon name="closeCircle" class="h-5 w-5 text-red-600 cursor-pointer" />
      </button>
      <modal-link
        route="conversations_responses.edit-photo"
        :params="{ id: card.id_card }"
      >
        <img
          :src="card.image_url"
          :alt="card.title"
          @error="imageLoadOnError"
          class="w-full h-64 bg-contain"
        />
      </modal-link>
    </div>
    <p class="mt-2">
      {{ card.content_text }}
    </p>
  </div>
</template>

<script>
import Icon from '@/components/ui/Icon'
import ModalLink from '@/components/ui/modals/ModalLink'

export default {
  components: { Icon, ModalLink },
  props: {
    card: {
      type: Object,
      default() {
        return {}
      },
    },
    isToDelete: {
      type: Boolean,
      default: false,
    },
    imgSelected: {
      type: String,
      default: '',
    },
    onSelectToDelete: {
      type: Function,
      required: true,
    },
  },
  methods: {
    imageLoadOnError(e) {
      e.target.src = require('@/assets/modules/NA.png')
    },
  },
}
</script>

<style scoped>
.card {
  width: 48%;
  @apply border-2 border-transparent;
}
.delete {
  @apply border-2 border-red-700;
}
.container-image .hide {
  display: none;
}
.container-image:hover .hide {
  display: block;
}
.disabled {
  opacity: 0.5;
}
</style>